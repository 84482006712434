import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { Currency } from '@uniswap/sdk-core';
import { FeeAmount, TICK_SPACINGS } from '@uniswap/v3-sdk';

import { IPositionPoolData } from '../../../types/pools';
import { useRootStore } from '../../../store/root';
import { usePoolDataQuery } from '../../../hooks/yldr/usePoolDataQuery';
import { usePoolTickSpacingQuery } from '../../../hooks/yldr/usePoolTickSpacingQuery';
import { usePoolGlobalStateQuery } from '../../../hooks/yldr/usePoolGlobalStateQuery';
import { usePool } from '../../LiquidityChartRangeInput/hooks/usePools';
import { checkMarketFeesExists } from '../../../utils/checkMarketFeesExists';

export const useRebalancePositionPoolData = ({ marketId, baseCurrency, quoteCurrency, feeAmount }: {
  marketId?: number;
  baseCurrency?: Currency;
  quoteCurrency?: Currency;
  feeAmount?: FeeAmount;
}): { data: IPositionPoolData | undefined, isLoading: boolean } => {
  const { marketName } = useRootStore((store) => store.getMarketDataById(marketId));
  const marketWithFees = checkMarketFeesExists(marketName);
  const currentFeeAmount = marketWithFees ? feeAmount || FeeAmount.LOW : 0

  const {
    data: estimatedUsdFeesPerDayData,
    isLoading: isLoadingEstimatedUsdFeesPerDay,
  } = usePoolDataQuery({
    marketId,
    token0: baseCurrency?.wrapped.address,
    token1: quoteCurrency?.wrapped.address,
    feeAmount: currentFeeAmount,
  });

  const { address, liquidity, estimated_usd_fees_per_day } = estimatedUsdFeesPerDayData;

  const { data: poolTickSpacingData, isLoading: isLoadingPoolTickSpacing } = usePoolTickSpacingQuery(address, marketName);
  const tickSpacing = currentFeeAmount ? TICK_SPACINGS[currentFeeAmount] : poolTickSpacingData?.[0];

  const { data: poolGlobalStateData, isLoading: isLoadingPoolGlobalState } = usePoolGlobalStateQuery(address, marketName);
  const { tick, price } = poolGlobalStateData;

  const pool = usePool(baseCurrency, quoteCurrency, currentFeeAmount);

  const getActiveTick = (tickCurrent: number | undefined, feeAmount: FeeAmount | undefined) =>
    typeof tickCurrent === 'number' && feeAmount
      ? Math.floor(tickCurrent / TICK_SPACINGS[feeAmount]) * TICK_SPACINGS[feeAmount]
      : undefined

  const activeTick = useMemo(
    () => tick || getActiveTick(pool[1]?.tickCurrent, currentFeeAmount),
    [pool, currentFeeAmount, tick]
  );

  const sqrtPriceX96 = BigNumber(price?.toString() || pool[1]?.sqrtRatioX96.toString() || 0);

  const poolPrice = BigNumber(sqrtPriceX96)
    .multipliedBy(sqrtPriceX96)
    .multipliedBy(BigNumber(10).pow(baseCurrency?.decimals || 0))
    .div(BigNumber(10).pow(quoteCurrency?.decimals || 0))
    .div(BigNumber(2).pow(192));

  const invertedPoolPrice = poolPrice.isPositive()
    ? BigNumber(1).div(poolPrice)
    : BigNumber(0);

  const isLoading =
    isLoadingEstimatedUsdFeesPerDay ||
    (!marketWithFees && isLoadingPoolTickSpacing) ||
    (!marketWithFees && isLoadingPoolGlobalState);

  if (
    !baseCurrency ||
    !quoteCurrency ||
    !marketId ||
    !marketName ||
    !address ||
    !tickSpacing ||
    !poolPrice ||
    activeTick === undefined
  ) return {
    data: undefined,
    isLoading,
  };

  return {
    data: {
      feeAmount: currentFeeAmount,
      marketId,
      marketName,
      address,
      liquidity,
      estimated_usd_fees_per_day,
      tickSpacing,
      activeTick,
      sqrtPriceX96,
      poolPrice,
      invertedPoolPrice,
      marketWithFees,
    },
    isLoading,
  };
};
